import React from "react";
import { Layout } from "./components/Layout";
import { Route, Redirect } from "react-router"
import { Home } from "./components/Home";
import ActivationWorkflow from "./components/Activation/ActiviationWorkFlow";
import ViewTransactionsWorkFlow from "./components/Transactions/ViewTransactionsWorkflow";

export default function AppLayout() {
    return (
        <Layout>
            <Route exact path='/' component={Home} />
            {/* <Route exact path='/'><Redirect to="/activate" /> </Route> */}
            <Route exact path="/activate" component={ActivationWorkflow} />
            <Route exact path="/viewTransactions" component={ViewTransactionsWorkFlow} />
        </Layout>
    )
}