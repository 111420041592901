import React, { useState } from "react";
import { BASEAPI } from "../../constants";
import { PostJson } from "../../helpers/fetch_helpers";
import { Button, Card, CardBody, CardText, CardTitle, Form, FormGroup, FormText, Input, Label } from "reactstrap";
import { getVerifyMethodLabel } from "../../enums";

export default function ViewVerify({memberDetail, verificationMethodId, nextStep}) {
    let [verificationCode, setVerificationCode] = useState();

    async function verifyCode() {
        var uri = BASEAPI + "/api/member/ConfirmVerificationCode/";
        var data = {
            memberNumber: memberDetail.memberNumber,
            code: verificationCode
        }
        var result = await PostJson(uri, data)
            .catch(function(error){
                console.log(error);
            })

        if (result) {
            nextStep();
        } else {
            console.log("failed to verify code");
        }
    }

    return (
        <Card>
            <CardBody>
                <CardTitle tag="h3">Enter Verification Code</CardTitle>
                <CardText>
                    <Form>
                        <FormText>Please enter the code you received by {getVerifyMethodLabel(verificationMethodId)} here:</FormText>
                        <FormGroup>
                            <Label>Code:</Label>
                            <Input type="text" id="verificationCode" onChange={e => setVerificationCode(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <Button color="danger" id="verify" onClick={verifyCode}>Verify Code</Button>
                        </FormGroup>
                    </Form>
                </CardText>
            </CardBody>
        </Card>
    )
}