import React, { useState } from "react";
import { Button } from "reactstrap";
import ProgressBar from "../Utility/ProgressBar";
import { Spinner } from "reactstrap";
import ActivationInitial from "./ActivationInitial";
import "./Activation.css";
import ActivationVerify from "./ActivationVerify";
import ActivationBanner from "./ActivationBanner";
import ActivationCardList from "./ActivationCardList";
import Confirmation from "./Confirmation";
import { useHistory } from "react-router";

export var ActivationStepEnum = {
    Validate: 1,
    Verify: 2,
    Activate: 3,
    Confirmation: 4
}

const progressLabels = ['Validate', 'Verify', 'Activate', 'Confirmation'];

export default function ActivationWorkflow() {
    let [currentStep, setCurrentStep] = useState(ActivationStepEnum.Validate); //useState(SubmissionStepEnum.General);
    let [loading, setLoading] = useState(false);
    let [memberDetail, setMemberDetail] = useState();
    let [eaid, setEaid] = useState();
    let [verificationMethodId, setVerificationMethodId] = useState();

    var history = useHistory();
    
    
    function returnHome(e) {
        history.push("/");
    }    

    function nextStep() {
        var nextValue = currentStep + 1;

        setCurrentStep(nextValue);
    }

    function prevStep() {
        var prevValue = currentStep - 1;
        
        setCurrentStep(prevValue);
    }


    function updatedMemberDetails(memberDetail) {
        setMemberDetail(memberDetail);
    }

    function verificationMessageSent(verificationMethodId) {
        setVerificationMethodId(verificationMethodId);
    }

    function getCurrentView() {
        switch (currentStep) {
            case ActivationStepEnum.Validate:
                return <ActivationInitial nextStep={nextStep} updatedMemberDetails={updatedMemberDetails} verificationMessageSent={verificationMessageSent}/>
            case ActivationStepEnum.Verify:
                return <ActivationVerify memberDetail={memberDetail} verificationMethodId={verificationMethodId} nextStep={nextStep} />
            case ActivationStepEnum.Activate:
                return <ActivationCardList memberDetail={memberDetail} eaid={memberDetail.eaid} nextStep={nextStep} />
            case ActivationStepEnum.Confirmation:
                return <Confirmation memberDetail={memberDetail} />
            default:
                throw new Error("Unknown Step")
        }
    }

    return (
        <div>
            <ActivationBanner />
            <div>
                <Button color="danger" onClick={returnHome}>Return Home</Button>
            </div>  
            <ProgressBar labels={progressLabels} currentStepNo={currentStep} />
          
            <div className="form-wrapper">
                {loading ? <Spinner /> : getCurrentView()}
            </div>
        </div>
    )
}