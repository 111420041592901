import React from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router";

export default function Confirmation({memberDetail}) {
    var history = useHistory();
    
    
    function returnHome(e) {
        history.push("/");
    }
    
    return (
        <div>
            <h3>Card Activation Confirmation</h3>
            <div>
                Thank you {memberDetail.fullName}, your card has been successfully activated. 
            </div>
            <div>
                A confirmation e-mail has been sent to {memberDetail.email}.
            </div>
            <div>
                <Button color="danger" onClick={returnHome}>Return Home</Button>
            </div>
        </div>
    )
}