export var VerifyMethodEnum = {
    Email: 1,
    SMS: 2,
}

export function getVerifyMethodLabel(verifyMethodTypeId) {
    switch (verifyMethodTypeId) {
        case VerifyMethodEnum.Email:
            return "E-Mail";
        case VerifyMethodEnum.SMS:
            return "SMS";
        default:
            return "Unknown";
    }
}

export var CardStatusEnum = {
    PreActive: 1,
    Active: 2
}

export function getCardStatusLabel(cardStatusId) {
    switch (cardStatusId) {
        case CardStatusEnum.PreActive:
            return "Pre Active";
        case CardStatusEnum.Active:
            return "Active";
        default:
            return "Unknown";
    }
}