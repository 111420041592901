import react from "react";
import { Card, CardBody, CardText, Label } from "reactstrap";
import { displayYesNo } from "../../helpers/formatting";
import { CardStatusEnum } from "../../enums";
import "./Card.css";

export default function CardSummary({card, isActive}) {
    var colr = isActive ? "danger" : "light";

    return (
        <Card color={colr}>
            <CardBody>
                <CardText className={'cardSummary ' + (isActive ? 'cardSummaryActive' : '')}>
                    <div><Label>Card Number: </Label>{card.cardNumber}</div>
                    <div><Label>Active: </Label>{displayYesNo(card.cardStatus === CardStatusEnum.Active)}</div><div className="balance"><Label>Balance: </Label> ${card.availableBalance}</div>
                </CardText>
            </CardBody>
        </Card>
    )
}