import React, { useState } from "react";
import { Alert, Button, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { BASEAPI, CHRISCO_NUMBER } from "../../constants";
import { PostJson } from "../../helpers/fetch_helpers";
import { VerifyMethodEnum, getVerifyMethodLabel } from "../../enums";
import { isEmpty } from "../../helpers/utils";

export default function ActivationInitial({nextStep, updatedMemberDetails, verificationMessageSent}) {
    let [eaid, setEaid] = useState();
    let [memberLoading, setMemberLoading] = useState(false);
    let [memberDetail, setMemberDetail] = useState(null);
    let [verifyMethodType, setVerifyMethodType] = useState(VerifyMethodEnum.Email);
    let [hasNoVerificationMethod, setHasNoVerificationMethod] = useState(false);

    let [eaidError, setEaidError] = useState();
    let [sendVerificationError, setSendVerificationError] = useState();

    async function submitEaid() {
        setMemberLoading(true);
        setEaidError(null);

        var uri = BASEAPI + "/api/member/VerifyEaid/" + eaid;
        var result = await PostJson(uri)
            .catch(function(error){
                setEaidError("An error occurred verifying your External Account Id: " + error.message + ". If this continues to occur, please call " + CHRISCO_NUMBER + " and speak to one of the team.");
                console.log(error);
            }).finally(() => {
                //stop spinner
                setMemberLoading(false)
            })

            if (result.hasMemberDetails) {
                setMemberDetail(result);
                updatedMemberDetails(result);

                if (!result.hasEmail && !result.hasMobile) {
                    setHasNoVerificationMethod(true);
                } else {
                    setDefaultVerifyMethod(result);
                }
            } else {
                //show error
                setEaidError("No card found matching external key: " + eaid + ". Please check the key you have entered is correct.  If you think this is a valid code, please call " + CHRISCO_NUMBER + " and speak to one of the team.");
            }
    }

    function setDefaultVerifyMethod(member) {
        if (member.hasMobile && !member.hasEmail) {
            setVerifyMethodType(VerifyMethodEnum.SMS);
        } else {
            setVerifyMethodType(VerifyMethodEnum.Email);
        }
    }

    function changeVerifyMethod(event) {
        if (event.target.value) {
            var verifyMethodTypeId = parseInt(event.target.value);
            setVerifyMethodType(verifyMethodTypeId);
        } else {
            setDefaultVerifyMethod(VerifyMethodEnum.Email);
        }
    }

    function needsChoice(member) {
        return member.hasEmail && member.hasMobile;
    }

    async function sendVerification() {
        setSendVerificationError(null);

        var uri = BASEAPI + "/api/member/SendVerificationForMember/";
        var data = {
            memberNumber: memberDetail.memberNumber,
            messageType: verifyMethodType
        }
        var result = await PostJson(uri, data)
            .catch(function(error){
                console.log(error);
                setSendVerificationError("An error occurred sending your verification code: " + error.message + ". If this continues to occur, please call " + CHRISCO_NUMBER + " and speak to one of the team.");
            })

        if (result) {
            verificationMessageSent(verifyMethodType);
            nextStep();
        } else {
            //console.log("failed to queue verification code");
            setSendVerificationError("An error occurred sending the verification code.  Please call " + CHRISCO_NUMBER + " and speak to one of the team.");
        }
    }

    function eaidKeyDown(e) {
        if (memberLoading || memberDetail || isEmpty(eaid))
            return;

        if (e.keyCode === 13) //enter
        {
            submitEaid();
        }
    }

    function eaidOnSubmit(e) {
        e.preventDefault();
    }

    return (
        <div className="workflowContainer">
            <div>
                To begin the process of activation we require the External Account Id (EAID) of the card you wish to activate. You will find the EAID on the activation letter you received with your card.
            </div>
            <div className="formGroup">
                <div className="groupTitle">Account Information</div>
                <Form onSubmit={eaidOnSubmit}>
                    <FormGroup>
                        <Label for="eaid">External Account Id (EAID): </Label>
                        <Input type="string" id="eaid" onChange={e => setEaid(e.target.value)} onKeyDown={eaidKeyDown} />
                    </FormGroup>
                    <FormGroup>
                        <Button color="danger" disabled={memberLoading || memberDetail || isEmpty(eaid)} onClick={submitEaid}>Submit</Button>
                    </FormGroup>
                </Form>
                {memberLoading && 
                    <div>
                        Searching for Card Details... <Spinner color="Primary" />
                    </div>}
                {memberDetail && 
                <Alert color="info">Card details found...</Alert>
                }
                {eaidError &&
                <Alert color="danger">{eaidError}</Alert>}
            </div>
            {memberDetail && 
                <div className="formGroup">
                    <div className="groupTitle">Member Verification</div>
                        <div>In order to confirm that you control the membership associated with this card account, you will be sent a verification code to the details stored on your membership. </div>
                        {memberDetail && needsChoice(memberDetail) && 
                            <div>Please choose which method you would prefer to recieve your verification code: 
                                <FormGroup inline>
                                    <FormGroup tag="fieldset" onChange={changeVerifyMethod}>
                                    {/* <legend>Verification Method Type: </legend> */}
                                    <FormGroup check inline>
                                        <Input name="verifyMethodType" type="radio" value={1} checked={verifyMethodType == 1} /> 
                                        {' '} 
                                        <Label check>E-Mail</Label>
                                    </FormGroup>
                                    <FormGroup check inline>
                                        <Input name="verifyMethodType" type="radio" value={2} checked={verifyMethodType == 2} /> 
                                        {' '} 
                                        <Label check>SMS</Label>
                                    </FormGroup>
                                </FormGroup>
                            </FormGroup>
                            </div>}
                            {!hasNoVerificationMethod &&
                            <>
                            <Alert color="info">
                                <div>You will recieve your verification code via: {getVerifyMethodLabel(verifyMethodType)} </div>
                            </Alert>
                        <FormGroup inline>
                            <Button color="danger" onClick={sendVerification}>Send Verification Code</Button>
                        </FormGroup>
                        </>
                        }
                        {hasNoVerificationMethod &&
                            <Alert color="danger">We have no contact details for you on your account, please call one of our team on 1800 830 830 to get the card activated.</Alert>
                        }
                        {sendVerificationError && 
                            <Alert color="danger">{sendVerificationError}</Alert>
                        }
                 </div>
            }
        </div>
    )
}