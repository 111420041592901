import React, { useEffect, useState } from "react";
import { GetJson } from "../../helpers/fetch_helpers";
import { BASEAPI } from "../../constants";
import { Col, Container, Row, Spinner } from "reactstrap";
import CardAccount from "../Card/CardAcount";
import CardSummaryList from "../Card/CardSummaryList";
import CardTransactions from "../Card/CardTransactions";

export default function ViewTransactions({memberDetail}) {
    let [loading, setLoading] = useState(false);
    let [cards, setCards] = useState();
    let [currentCardId, setCurrentCardId] = useState();

    async function getMembersCards() {
        setLoading(true);
        var uri = BASEAPI + "/api/member/GetMemberCardCollection/" + memberDetail.memberNumber;

        var result = await GetJson(uri, null)
            .catch(function(error){
                console.log(error);
            })

        setCards(result);
        setLoading(false);
    }

    function selectionChanged(cardId) {
        setCurrentCardId(cardId);
    }

    useEffect(() => {
        getMembersCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberDetail])
    
    return (
        <div>
            {loading && <>Loading Cards... <Spinner/> </>}
            {cards != null && cards.length === 0 &&
                <div>No Active Cards</div>
            }
            {cards && cards.length > 0 &&
                <div className="formGroup">
                    <div className="groupTitle">Cards for: {memberDetail.fullName} [{memberDetail.memberNumber}]</div>

                <Container>
                    <Row>
                        <Col md="3" xs="12">
                            <CardSummaryList cards={cards} selectedCardChange={selectionChanged} />
                        </Col>
                        <Col md="9" xs="12">
                            <CardTransactions cardId={currentCardId} />
                        </Col>
                    </Row>
                </Container>

                {/* 
                    {cards.map((card, index) => {
                        return <CardAccount key={index} index={index} card={card} selectionChanged={selectionChanged}></CardAccount>
                    })} */}
                </div>
            }
        </div>
    )
}