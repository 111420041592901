export const BASEAPI = ".";

export const CHRISCO_NUMBER = "1800 830 830";

export const CHRISCO_SITE = "www.chrisco.com.au";

export const CHRISCO_FACEBOOK = "/chrisco.au";

export const CHRISCO_INSTAGRAM = "@chrisco.au";

export const TNCS_LINK = "https://www.chrisco.com.au/Topic/TermsAndConditions/TC_MasterCard";

export const CARD_LINK = "https://www.chrisco.com.au/home/category?rc=Mastercard%C2%AE"