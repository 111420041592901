import React, { useState } from "react";
import CardSummary from "./CardSummary";
import { ListGroup, ListGroupItem } from "reactstrap";

export default function CardSummaryList({cards, selectedCardChange}) {
    let [selectedCardId, setSelectedCardId] = useState();
    
    function changeSelectedCard(cardId) {
        setSelectedCardId(cardId);
        selectedCardChange(cardId);
    }

    return (
        <div>
            <h5>Select Card</h5>
            <ListGroup>
            {cards.map((card, index) => {
                var isActive = selectedCardId === card.cardId;
                return (
                    <ListGroupItem key={index} tag="button" action onClick={() => changeSelectedCard(card.cardId)}>
                        <CardSummary key={index} card={card} isActive={isActive} />
                    </ListGroupItem>
                )
            })}
            </ListGroup>
        </div>
    )
}