import React, { useState } from "react";
import { CardStatusEnum, getCardStatusLabel } from "../../enums";
import { Button, Card, CardText, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, Spinner, Table } from "reactstrap";
import "./Card.css";
import { displayYesNo } from "../../helpers/formatting";
import { format } from "date-fns";
import { isEmpty } from "../../helpers/utils";
import { BASEAPI } from "../../constants";
import { GetJson } from "../../helpers/fetch_helpers";

export default function CardAccount({index, card, selectionChanged}) {
    let [toActivate, setToActivate] = useState(false);
    let [extraTrans, setExtraTrans] = useState();
    let [loadingTrans, setLoadingTrans] = useState(false);

    function toggleCheck(checked) {
        setToActivate(checked);
        selectionChanged(card.cardId, checked);
    }

    async function getMoreTransactions(cardId) {
        //TODO - ask server for extra transactions
        setLoadingTrans(true);

        var uri = BASEAPI + "/api/member/GetMoreTransactions/" + cardId;

        var result = await GetJson(uri, null)
        .catch(function(error){
            console.log(error);
        })
        .finally(() => {
            setLoadingTrans(false);
        })
        
        setExtraTrans(result);
    }

    function getTranRow(tran, index) {
        return (<tr key={index}><td>{format(new Date(tran.transactionTime), 'dd/MM/yyyy hh:mm:ss')}</td><td>{tran.reference}</td><td>${tran.amount}</td></tr>)
    }

    return (
        <Card color="light" className="cardCard">
            <CardTitle tag="h5">{index !== null ? (index+1) : 'New'}</CardTitle>
            <CardText>
                <Container>
                    <Row>
                        {/* {card.cardStatus === CardStatusEnum.PreActive && <Col xs="1">
                            <FormGroup check inline>
                                <Input type="checkbox" id="activateCheck" value={toActivate} onChange={() => toggleCheck(!toActivate)} />
                            </FormGroup>
                        </Col>} */}
                        <Col md="6" sm="12" xs="12">
                            <div><Label>Order#: </Label>{card.orderNumber} [{card.catalogueName}]</div>
                            <div><Label>Card Number: </Label>{card.cardNumber}</div>
                            {/* <div><Label>Status: </Label>{getCardStatusLabel(card.cardStatus)}</div> */}
                            <div><Label>Active: </Label>{displayYesNo(card.cardStatus === CardStatusEnum.Active)}</div><div className="balance"><Label>Balance: </Label> ${card.availableBalance}</div>
                        </Col>
                        {card.last5Transactions &&
                        <Col md="6" sm="12" xs="12">
                            <h5>Previous Transactions</h5>
                            <Table size="sm" striped hover>
                                <thead>
                                    <th>Transaction Time</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                </thead>
                            {card.last5Transactions.map((tran, index) => {
                                return getTranRow(tran, index);
                            })}
                            {extraTrans && extraTrans.map((tran, index) => {
                                return getTranRow(tran, index);
                            })}
                            </Table>
                            {card.hasMoreTransactions && isEmpty(extraTrans) &&
                            <Button size="sm" color="link" onClick={() => getMoreTransactions(card.cardId)}>Show More...</Button>}
                            {loadingTrans && <Spinner color="secondary">Loading Transactions...</Spinner>}
                        </Col>
                        }
                    </Row>
                </Container>
            </CardText> 
        </Card>
    )
}