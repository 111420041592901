import React, { useEffect, useState } from "react";
import { BASEAPI } from "../../constants";
import { GetJson } from "../../helpers/fetch_helpers";
import { Spinner, Table } from "reactstrap";
import { format } from "date-fns";

export default function CardTransactions({cardId}) {
    let [transactions, setTransactions] = useState();
    let [loadingTrans, setLoadingTrans] = useState();

    async function getTransactions() {
        setLoadingTrans(true);

        var uri = BASEAPI + "/api/member/GetAllTransactions/" + cardId;

        var result = await GetJson(uri, null)
        .catch(function(error){
            console.log(error);
        })
        .finally(() => {
            setLoadingTrans(false);
        })
        
        setTransactions(result);
    }

    useEffect(() => {
        getTransactions();
    }, [cardId])

    return (
        <div>
            {loadingTrans && <>Loading Transactions... <Spinner /></>}    
            
            {transactions && <div>
                <Table striped hover>
                                <thead>
                                    <th>Transaction Time</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                </thead>
                            {transactions.map((tran, index) => {
                                return <tr key={index}>
                                    <td>{format(new Date(tran.transactionTime), 'dd/MM/yyyy hh:mm:ss')}</td>
                                    <td>{tran.reference}</td>
                                    <td>${tran.amount}</td>
                                </tr>
                            })}
                            </Table>
            </div>}
        </div>
    )
}