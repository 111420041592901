import React, { useState } from "react";
import { Button } from "reactstrap";
import ViewInitial from "./ViewInitial";
import ViewVerify from "./ViewVerify";
import { Spinner } from "reactstrap";
import ViewTransactions from "./ViewTransactions";
import ViewBanner from "./ViewBanner";
import { useHistory } from "react-router";

export var ViewStepEnum = {
    Validate: 1,
    Verify: 2,
    Show: 3,
}

export default function ViewTransactionsWorkFlow() {
    let [loading, setLoading] = useState(false);
    let [currentStep, setCurrentStep] = useState(ViewStepEnum.Validate);
    let [memberDetail, setMemberDetail] = useState();
    let [verificationMethodId, setVerificationMethodId] = useState();

    var history = useHistory();
    
    
    function returnHome(e) {
        history.push("/");
    }   

    function nextStep() {
        var nextValue = currentStep + 1;

        setCurrentStep(nextValue);
    }

    function updatedMemberDetails(memberDetail) {
        setMemberDetail(memberDetail);
    }

    function verificationMessageSent(verificationMethodId) {
        setVerificationMethodId(verificationMethodId);
    }

    function getCurrentView() {
        switch (currentStep) {
            case ViewStepEnum.Validate:
                return <ViewInitial updatedMemberDetails={updatedMemberDetails} verificationMessageSent={verificationMessageSent} nextStep={nextStep} />
            case ViewStepEnum.Verify:
                return <ViewVerify memberDetail={memberDetail} verificationMethodId={verificationMethodId} nextStep={nextStep} />
            case ViewStepEnum.Show:
                return <ViewTransactions memberDetail={memberDetail} />
            default:
                throw new Error("Unknown Step")
        }
    }

    return (
        <div>
            <ViewBanner />
             <div>
                <Button color="danger" onClick={returnHome}>Return Home</Button>
            </div> 
            <div className="form-wrapper">
                {loading ? <Spinner /> : getCurrentView()}
            </div>
        </div>
    )
}