import React, { useState } from "react";
import { Alert, Button, Card, CardBody, CardText, CardTitle, Form, FormGroup, FormText, Input, Label } from "reactstrap";
import { getVerifyMethodLabel } from "../../enums";
import { BASEAPI, CHRISCO_NUMBER } from "../../constants";
import { PostJson } from "../../helpers/fetch_helpers";

export default function ActivationVerify({memberDetail, verificationMethodId, nextStep}) {
    let [verificationCode, setVerificationCode] = useState();
    let [sendVerificationError, setSendVerificationError] = useState();

    async function verifyCode() {
        setSendVerificationError(null);

        var uri = BASEAPI + "/api/member/ConfirmVerificationCode/";
        var data = {
            memberNumber: memberDetail.memberNumber,
            code: verificationCode
        }
        var result = await PostJson(uri, data)
            .catch(function(error){
                //console.log(error);
                setSendVerificationError("An error occurred confirming your verification code: " + error.message + ". If this continues to occur, please call " + CHRISCO_NUMBER + " to speak to one of the team.")
            })

        if (result) {
            nextStep();
        } else {
            //console.log("failed to verify code");
            setSendVerificationError("An error occurred confirming your verification code. If this continues to occur, please call " + CHRISCO_NUMBER + " to speak to one of the team.")
        }
    }

    function codeKeyDown(e) {        
        if (e.keyCode === 13) //enter
        {
            verifyCode();
        }
    }

    function codeOnSubmit(e) {
        e.preventDefault();
    }

    return (
        <Card>
            <CardBody>
                <CardTitle tag="h3">Enter Verification Code</CardTitle>
                <CardText>
                    <Form onSubmit={codeOnSubmit}>
                        <FormText>Please enter the code you received by {getVerifyMethodLabel(verificationMethodId)} here:</FormText>
                        <FormGroup>
                            <Label>Code:</Label>
                            <Input type="text" id="verificationCode" onChange={e => setVerificationCode(e.target.value)} onKeyDown={codeKeyDown} />
                        </FormGroup>
                        <FormGroup>
                            <Button color="danger" id="verify" onClick={verifyCode}>Verify Code</Button>
                        </FormGroup>
                        {sendVerificationError && 
                                    <Alert color="danger">{sendVerificationError}</Alert>
                                }
                    </Form>
                </CardText>
            </CardBody>
        </Card>
    )
}