import React from "react";
import topBanner from "../../images/2024-Banner-HomePage-MASTERCARD.jpg";

export default function ViewBanner() {
    return (
        <div>
            <h3>My Cards</h3>
            <div><img className="topBannerImage" src={topBanner} alt="Chrisco Hampers" /></div>
            <div>
            &nbsp;
            </div>
        </div>
    )
}