import React from "react";
import { FaCheck } from "react-icons/fa";

import "./ProgressBar.css";

export default function ProgressBar({labels,currentStepNo}) {
    var count = labels.length;

    var style = {
        "--stepCount": count
    }

    return (
        <div className="progress" style={style}>
            <ul className="progressBar" >
                {labels.map((label, index) => {       
                    return (
                        <li key={index} className={(index == currentStepNo-1 ? "active" : (index < currentStepNo-1 ? "complete" : ""))}>{label}</li>
                    )
                })}    
            </ul>
        </div>
    )
}