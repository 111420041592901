import React from 'react';
import './custom.css'
import AppLayout from './AppLayout';

export default function App() {
  document.title = 'Chrisco Card Activation';

  return (
    <AppLayout />
  )
}
